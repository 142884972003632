<script>
import { TEL_NUMBER } from "~/constants";
</script>

<template>
  <ButtonTelLink
    class="flex items-center justify-center px-2 py-4"
    :number="TEL_NUMBER"
  >
    <Icon class="text-white mr-2" size="24" name="mdi:cellphone" />
    <p>電話でお問い合わせ</p>
  </ButtonTelLink>
</template>
