<script setup lang="ts">
import { FavoriteMenu } from "#components";
import { useModal } from "vue-final-modal";
import useSekouFavorite from "~/composables/useSekouFavorite";
import usePlanFavorite from "~/composables/usePlanFavorite";

interface Props {
  icon_size?: number;
  text_size?: number;
}

withDefaults(defineProps<Props>(), {
  icon_size: 24,
  text_size: 12,
});

const { favoriteSekou } = useSekouFavorite();
const { favoritePlan } = usePlanFavorite();

// mobileメニューモーダル
const { open, close } = useModal({
  component: FavoriteMenu,
  attrs: {
    onClose: () => {
      close();
    },
  },
});
</script>

<template>
  <button class="relative" @click="open()">
    <div class="w-fit mx-auto relative pc:static">
      <Icon class="mx-1 mt-1" :size="`${icon_size}`" name="mdi:heart" />
      <span
        v-if="favoriteSekou.length || favoritePlan.length"
        class="absolute top-0 left-[90%] px-1 rounded-full bg-red-500 text-white text-xs"
      >
        {{ favoriteSekou.length + favoritePlan.length }}
      </span>
    </div>
    <span class="block" :style="{ fontSize: `${text_size}px` }"
      >お気に入り</span
    >
  </button>
</template>
