<script setup lang="ts">
const footerMakers = [
  { name: "LIXIL", to: "https://www.lixil.co.jp/", external: true },
  { name: "YKKAP", to: "https://www.ykkap.co.jp/", external: true },
  { name: "三協アルミ", to: "https://alumi.st-grp.co.jp/", external: true },
  {
    name: "四国化成",
    to: "https://kenzai.shikoku.co.jp/index.html",
    external: true,
  },
  { name: "タカショー", to: "https://takasho.co.jp/", external: true },
  { name: "ユニソン", to: "https://www.unison-net.com/", external: true },
  { name: "Panasonic", to: "https://sumai.panasonic.jp/", external: true },
  { name: "TOTO", to: "https://jp.toto.com/", external: true },
  {
    name: "タカラスタンダード",
    to: "https://www.takara-standard.co.jp/",
    external: true,
  },
  { name: "トクラス", to: "https://www.toclas.co.jp/", external: true },
  { name: "クリナップ", to: "https://cleanup.jp/", external: true },
  {
    name: "イナバ物置",
    to: "https://www.inaba-ss.co.jp/monooki/",
    external: true,
  },
  { name: "ナスラック", to: "https://www.nasluck.co.jp/", external: true },
  { name: "ヨド物置", to: "https://www.yodomonooki.jp/", external: true },
  {
    name: "ディーズガーデン",
    to: "https://www.deasgarden.jp/",
    external: true,
  },
  { name: "タクボ物置", to: "https://www.e-ty.co.jp/", external: true },
];
</script>

<template>
  <!-- history -->
  <ClientOnly>
    <SlideUserHistory />
  </ClientOnly>

  <!-- footer -->
  <footer class="l-footer">
    <div class="p-footer">
      <FooterLogo />
      <div class="p-footer__nav mb-8">
        <div class="p-footer__pages">
          <p class="p-footer__title">事業紹介</p>
          <FooterLinks />
        </div>

        <div class="p-footer__maker">
          <p class="p-footer__title">取扱主要メーカー</p>
          <ul class="p-footer__list">
            <li v-for="d in footerMakers" :key="d.name" class="p-footer__item">
              <NuxtLink
                :to="d.to"
                :external="d.external"
                class="flex items-center justify-between"
              >
                <p class="mr-1">{{ d.name }}</p>
                <Icon
                  v-if="d.external"
                  class="text-white mr-2"
                  name="mdi:open-in-new"
                />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-8">
        <ul class="flex flex-col md:flex-row gap-4">
          <li class="w-fit mx-auto">
            <NuxtLink to="/recruit/newgrad">
              <ImageEl
                src="/images/footer/newgrad.png"
                alt="新卒採用フォーム"
                width="240"
                height="100"
                sizes="240px"
                loading="lazy"
                format="webp"
              />
            </NuxtLink>
          </li>
          <li class="w-fit mx-auto">
            <NuxtLink to="/recruit/career">
              <ImageEl
                src="/images/footer/carrer.png"
                alt="中途採用フォーム"
                width="240"
                height="100"
                sizes="240px"
                loading="lazy"
                format="webp"
              />
            </NuxtLink>
          </li>
          <li class="w-fit mx-auto">
            <NuxtLink to="/recruit/contractor">
              <ImageEl
                src="/images/footer/contractor.png"
                alt="職人募集フォーム"
                width="240"
                height="100"
                sizes="240px"
                loading="lazy"
                format="webp"
              />
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="text-center text-gray-300 wrapper">
        <p>Copyright(C)2006-2023.Family Teien Co.,Ltd.All right reserved</p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.p-footer {
  $this: &;
  @apply bg-green-800 text-white select-none py-4;
  &__nav {
    @apply tablet:flex tablet:px-4;
  }
  &__link {
    @apply inline-block text-sm;
    &:hover {
      @apply cursor-pointer opacity-80;
    }
  }
  &__title {
    @apply text-lg p-4  text-center tablet:text-left;
  }
  // ページ一覧
  &__pages,
  &__maker {
    @apply tablet:w-1/2;
    #{$this}__list {
      @apply flex justify-start flex-wrap border-t tablet:border-none;
    }
    #{$this}__item {
      @apply w-1/2 border-b border-r p-2  tablet:border-none hover:bg-green-900;
    }
  }
}
</style>
