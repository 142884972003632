<script setup lang="ts">
const { showScrollTop } = useScrollTop(false);
showScrollTop.value = false;
function scrollTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const isShow = ref(false);

function showButton() {
  if (100 <= window.scrollY) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", showButton, { passive: true });
});
</script>

<template>
  <Transition name="slide-fade-r">
    <div v-show="showScrollTop && isShow" @click="scrollTop()" class="button">
      <Icon class="text-white" size="32" name="mdi:chevron-up" />
    </div>
  </Transition>
</template>
<style scoped>
.button {
  @apply flex items-center justify-center z-10 bg-orange-400 fixed bottom-4 right-4 rounded-full aspect-square cursor-pointer w-12;
}
</style>
