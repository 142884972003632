<template>
  <div class="flex justify-center flex-col items-center tablet:flex-row">
    <ImageEl
      class="w-[180px] mb-4"
      src="/images/logo_2.png"
      alt="ファミリー庭園"
      width="768"
      height="198"
      sizes="sm:270px"
      format="webp"
      loading="lazy"
    />
    <p class="text-sm">ファミリー庭園株式会社</p>
  </div>
</template>
